// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState, useLayoutEffect } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useMeasure } from 'react-use';
import Hotjar from '@hotjar/browser';
import _ from 'lodash';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import {
	onAuthStateChanged,
	signOut,
	signInWithCustomToken,
	setPersistence,
	browserSessionPersistence,
	browserLocalPersistence,
} from 'firebase/auth';
import {
	getOS,
	Portal,
	useDarkMode,
	RegisteredUserContext,
	OemProfileContext,
} from '@ekhodealer/ekho-common/components';
import { OEM_KEY_TO_HOTJAR_SITE_ID } from '@ekhodealer/ekho-common';
import { getOemId } from '@ekhodealer/ekho-common/utils';
import AllPageNamesContext from '../contexts/allPageNamesContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import { demoPages, layoutMenu } from '../menu';
import { ACCOUNT_TYPE, COLORS, ENV } from '../common/data/constants';
import { auth } from '../firebase';
import {
	getBuyerData,
	getAuthenticatedOemData,
	getUnauthenticatedOemData,
	// completeFirstLogin,
	fetchAllPageNames,
	createLog,
	exchangeToken,
	buyerActive,
} from '../serverCalls';
import MobileViewContext from '../contexts/mobileViewContext';
import SupportModalContext from '../contexts/supportModalContext';
import { identify_known_user, group_user } from '../Utils/analyticsFuncs';
import VehicleDetailsLoading from '../pages/vehicle/loading/VehicleDetailsLoading';
import { isMobileDevice } from '../Utils/util_funcs';

const App = () => {
	const navigate = useNavigate();
	const usePathname = () => {
		const location = useLocation();
		return location.pathname;
	};
	const loc = usePathname();
	// Testing our hotjar
	useEffect(() => {
		if (process.env.REACT_APP_CURR_ENV === ENV.PRODUCTION) {
			const siteId = OEM_KEY_TO_HOTJAR_SITE_ID.BUYER_PORTAL[process.env.REACT_APP_OEM_KEY];
			if (siteId === undefined) {
				return;
			}
			const hotjarVersion = 6;
			Hotjar.init(siteId, hotjarVersion);
		}
	}, []);
	getOS();
	// loading screen
	const [isLoading, setIsLoading] = useState(true);
	// for registered user context
	const [allPageNames, setAllPageNames] = useState(null);
	const allPageNamesValue = useMemo(() => ({ allPageNames, setAllPageNames }), [allPageNames]);
	const [registeredUserProps, setRegisteredUser] = useState(null);
	const registeredUserValue = useMemo(
		() => ({ registeredUserProps, setRegisteredUser }),
		[registeredUserProps],
	);
	// for oem profile context
	const [oemProfileProps, setOemProfile] = useState(null);
	const oemProfileValue = useMemo(() => ({ oemProfileProps, setOemProfile }), [oemProfileProps]);
	useEffect(() => {
		if (oemProfileProps !== null && registeredUserProps !== null) {
			// Identify and group user once they're logged in using Segment method
			identify_known_user(registeredUserProps, oemProfileProps);
			group_user(oemProfileProps);
		}
	}, [oemProfileProps, registeredUserProps]);

	// Used to check if we're at /login, /reset, /usermgmt
	const isDefaultAccessibleLocation = () => {
		if (loc === '/login' || loc === '/reset' || loc === '/usermgmt' || loc === '/mfa') {
			return true;
		}
		return false;
	};

	useEffect(() => {
		setIsLoading(true);
		async function authenticateWithIdToken(token) {
			// Exchange and sign in with custom token
			const { customToken } = await exchangeToken(token);
			if (customToken) {
				await signInWithCustomToken(auth, customToken);
			}
		}
		async function authenticateWithCustomToken(token) {
			await signInWithCustomToken(auth, token);
		}
		onAuthStateChanged(auth, async (buyerAuth) => {
			// check URL params for idToken
			const urlParams = new URLSearchParams(window.location.search);
			const idToken = urlParams.get('idToken');
			const customToken = urlParams.get('customToken');
			if (idToken) {
				try {
					await setPersistence(
						auth,
						isMobileDevice() ? browserLocalPersistence : browserSessionPersistence,
					);
					if (buyerAuth) {
						await signOut(auth);
						// signing the buyer out will re-trigger the onAuthStateChanged listener
					} else {
						const url = new URL(window.location);
						urlParams.delete('idToken');
						url.search = urlParams.toString();

						window.history.pushState(null, '', url.toString());
						await authenticateWithIdToken(idToken);
					}
					return;
				} catch (error) {
					createLog(
						'Authentication-failed',
						`Error authenticating with token: ${error}`,
						'ERROR',
						{ oemKey: process.env.REACT_APP_OEM_KEY },
					);
				}
			} else if (customToken) {
				try {
					await setPersistence(
						auth,
						isMobileDevice() ? browserLocalPersistence : browserSessionPersistence,
					);
					if (buyerAuth) {
						await signOut(auth);
						// signing the buyer out will re-trigger the onAuthStateChanged listener
					} else {
						const url = new URL(window.location);
						urlParams.delete('customToken');
						url.search = urlParams.toString();

						window.history.pushState(null, '', url.toString());
						await authenticateWithCustomToken(customToken);
					}
					return;
				} catch (error) {
					createLog(
						'Authentication-failed',
						`Error authenticating with custom token: ${error}`,
						'ERROR',
						{ oemKey: process.env.REACT_APP_OEM_KEY },
					);
				}
			}
			const tokenResult = await buyerAuth?.getIdTokenResult(true);
			if (tokenResult?.claims?.buyerId) {
				buyerActive(tokenResult?.claims?.buyerId);
			} else if (tokenResult?.claims?.tokenOnly) {
				buyerActive(buyerAuth.uid);
			}
			const accountType = tokenResult?.claims?.admin
				? ACCOUNT_TYPE.ADMIN
				: ACCOUNT_TYPE.BUYER;
			if (
				// user is signed in
				// and (user email has been verified or user is using an idToken or user is using an email link)
				// and user is buyer type
				registeredUserProps === null &&
				(buyerAuth?.emailVerified ||
					tokenResult?.claims?.email_verified ||
					tokenResult?.claims?.tokenOnly ||
					tokenResult?.claims?.isEmailLink) &&
				accountType === ACCOUNT_TYPE.BUYER
			) {
				// -- get authenticated version after login if not already set or only unauthenticated version set --
				getBuyerData()
					.then(({ buyer }) => {
						setRegisteredUser(buyer);
					})
					.catch(() => {
						signOut(auth);
						navigate(`/login`);
					});
				// get oem data if not already set or only unauthenticated version set
				if (
					(oemProfileProps === null || !('adminId' in oemProfileProps)) &&
					auth.currentUser
				) {
					const oemId = getOemId(
						process.env.REACT_APP_OEM_KEY,
						process.env.REACT_APP_BRAND_KEY,
					);
					getAuthenticatedOemData(oemId).then(({ oem }) => {
						setOemProfile(oem);
					});
				}
				// if allPageNames is null, fetch all page names
				if (allPageNames === null) {
					fetchAllPageNames().then(({ pageNames }) => {
						setAllPageNames(pageNames);
					});
				}

				// Set hour AFK timer
				let timer;
				const resetTimer = () => {
					clearTimeout(timer);
					timer = setTimeout(() => {
						// Log out the user
						signOut(auth);
						navigate(`/login`);
					}, 60 * 60 * 1000); // 1 hour
				};

				// Using lodash to throttle the event
				const throttledResetTimer = _.throttle(resetTimer, 1000);

				// Listen for any events that should reset the timer
				document.addEventListener('mousemove', throttledResetTimer);
				document.addEventListener('keypress', throttledResetTimer);
				document.addEventListener('touchmove', throttledResetTimer);

				// Initialize the timer
				resetTimer();
			} else {
				if (buyerAuth && accountType === ACCOUNT_TYPE.ADMIN) {
					signOut(auth);
				}
				// make sure they are allowed to be in the current location
				if (!isDefaultAccessibleLocation()) {
					const email = urlParams.get('email')?.toLocaleLowerCase();
					navigate(email ? `/login?email=${encodeURIComponent(email)}` : '/login');
				}
			}
			setIsLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// for mobile view context
	const [mobileView, setMobileView] = useState(false);
	const mobileViewValue = useMemo(() => ({ mobileView, setMobileView }), [mobileView]);
	// for support modal context
	const [supportModalOpen, toggleSupportModal] = useState(false);
	const supportModalValue = useMemo(
		() => ({ supportModalOpen, toggleSupportModal }),
		[supportModalOpen],
	);
	const [fullSizeRef, fullSizeDims] = useMeasure();
	// const MOBILE_WIDTH_BREAKPOINT = 992; // 768 (main content) + 60 (aside)
	useEffect(() => {
		const isMobileView = fullSizeDims.width
			? fullSizeDims.width < process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE
			: // use the window width if the ref hasn't been set yet
			  window.innerWidth < process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;
		setMobileView(isMobileView);
	}, [fullSizeDims]);

	useEffect(() => {
		async function getOemData(type) {
			const oemId = getOemId(process.env.REACT_APP_OEM_KEY, process.env.REACT_APP_BRAND_KEY);
			if (type === 'unauth') {
				const { oem } = await getUnauthenticatedOemData(oemId);
				setOemProfile(oem);
			} else if (type === 'auth') {
				getAuthenticatedOemData(oemId).then(({ oem }) => {
					setOemProfile(oem);
				});
			}
		}

		if (oemProfileProps === null) {
			if (isDefaultAccessibleLocation()) {
				getOemData('unauth');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loc]);

	/**
	 * Dark Mode
	 */
	const { themeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		demoPages.login.path,
		demoPages.signUp.path,
		layoutMenu.blank.path,
		demoPages.userMgmt.path,
		demoPages.reset.path,
		demoPages.mfa.path,
		demoPages.vehicleDetails.path,
	];

	return (
		<>
			{isLoading && <VehicleDetailsLoading />}
			{!isLoading && (
				<RegisteredUserContext.Provider value={registeredUserValue}>
					<OemProfileContext.Provider value={oemProfileValue}>
						<AllPageNamesContext.Provider value={allPageNamesValue}>
							<MobileViewContext.Provider value={mobileViewValue}>
								<SupportModalContext.Provider value={supportModalValue}>
									<ThemeProvider theme={theme}>
										{/* Only load the app if user is signed in or we are in a default accessible location */}
										{oemProfileProps !== null &&
											(isDefaultAccessibleLocation() ||
												registeredUserProps !== null) && (
												<div
													ref={fullSizeRef}
													className='app'
													style={{
														zIndex: 1,
														// overflow: 'scroll',
													}}>
													<Routes>
														{withOutAsidePages.map((path) => (
															<Route key={path} path={path} />
														))}
														{!mobileView && (
															<Route
																path='*'
																element={
																	<Aside
																		toggleSupportModal={
																			toggleSupportModal
																		}
																	/>
																}
															/>
														)}
													</Routes>
													<Wrapper />
												</div>
											)}
										{/* Loading screen */}
										{(oemProfileProps === null ||
											(!isDefaultAccessibleLocation() &&
												registeredUserProps === null)) && (
											<VehicleDetailsLoading />
										)}
										<Portal id='portal-notification'>
											<ReactNotifications />
										</Portal>
									</ThemeProvider>
								</SupportModalContext.Provider>
							</MobileViewContext.Provider>
						</AllPageNamesContext.Provider>
					</OemProfileContext.Provider>
				</RegisteredUserContext.Provider>
			)}
		</>
	);
};

export default App;
